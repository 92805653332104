* {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    margin: 0;
    padding: 0;
    background-color: #F5F5F5;
}

.p-datatable-emptymessage{
    display: none;
}

#root {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.p-invalid{
    color: red !important;
}

.p-dialog-header-close {
    color: white !important;
    background: #FBC02D !important;
    border: 1px solid #FBC02D !important;
    border-radius: 3px !important;
}

/* .p-datatable{
    width: calc(100% - 30px);
    margin: 15px;
    overflow: auto;
    height: 85%;
} */

 /* .p-datatable-thead {
    position: sticky;
    top: -12px;
    z-index: 1;
    background: #fff;
    height: 50px;
    
} */

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 9px;
}

*::-webkit-scrollbar-track {
    background: none;
}

*::-webkit-scrollbar-thumb {
    background-color: #25247B;
    border-radius: 20px;
}

.p-tabview{
    width: 100%;
}

.p-datatable{
    flex: 1 1 1e-09px;
    width: calc(100% - 30px);
    margin: 15px;
}

.p-datatable-thead {
    vertical-align: top;
    border-bottom: 1px solid #25247B;
    position: sticky;
    top: 0;
    background: #F5F5F5;
    z-index: 1;
    height: 50px;
}

.p-paginator{
    background: none;
}

.p-datatable-thead > tr > th{
    border: none !important;
    background: none !important;
    color: #25247B !important;
    padding: 5px 5px !important;
}

.p-datatable-tbody{
   
}

.p-datatable-tbody > tr {
    box-shadow: 0px 0px 5px rgb(185, 185, 185);
}

.p-datatable-tbody > tr > td{
    border: none !important;
    background: none !important;
    color: #25247B !important;
    padding: 5px 5px !important;
} 


.p-datatable-tbody > tr {
    height: 45px;
    max-height: 45px !important;
    box-shadow: 0px 0px 5px rgb(185, 185, 185);
}

.p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.p-scrollpanel-bar {
    background-color: #25247B !important;
}

.p-scrollpanel-grabbed {
    background-color: #25247B !important;
}

.p-scrollpanel-bar:hover {
    background-color: #25247B;
}

.desabilitado{
    background-color:#d4d4d4 !important;
}

.desabilitadoText{
    background-color:#c4c4c4 !important;
}

.p-editor-container{
    width: 100%;
    display: flex;
    flex: 1 1 1e-09px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.p-editor-toolbar{
    width: 100%;
}

.p-editor-content{
    width: 100%;
    flex: 1 1 1e-09px;
    overflow: hidden;
}